import {
  fetchApi
} from "@/utils/axios";
import URL from "@/api/urlConfig"
const prefix = '/api/dscloud-appservice-management'
// 项目下拉
export const projectList = () => {
  return fetchApi(URL.BINDING_PROJECT_LIST, null)
}

// 新增
export const addSkin = (data) => {
  return fetchApi(URL.SKIN_ADD, data, 'post', 'data')
}

// 列表
export function listSkin(params) {
  return fetchApi(URL.SKIN_LIST, params, "get", 'params', true);
}

// 删除
export function deleteSkin(params) {
  return fetchApi(URL.SKIN_DELETE+'?id='+params.id, {}, "DELETE");
}

// 启用禁用
export function enabledSkin(params) {
  return fetchApi(URL.SKIN_ENABLED, params, "get");
}

// 详情
export function infomationSkin(params) {
  return fetchApi(URL.SKIN_INFO, params, "get");
}

// 修改
export const updateSkin = (data) => {
  return fetchApi(URL.SKIN_UPDATE, data, 'put', 'data')
}

/**
  * @description: 根据皮肤项目id查询对应的页面主题
  * @author: cxd
  * @time: 2024/5/27
 **/
export const getPageInfoByProjectId = (id, penetrate) => {
  const params = {
    projectId: id,
    penetrate: penetrate
  }
  return fetchApi(prefix + '/web/homepage/findSkinHomePageByProject', params, 'GET', 'params')
}
